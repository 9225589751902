define(['jThumbsM', 'jThumbs', 'siteObj', 'jquery'], function(jThumbsM, jThumbs, siteObj, $) {
  
  var productPageVariationImages = function() {
    var _self = this;


    _self._init = function() {

      if (siteObj.productImages) {

        for (var prop in siteObj.productImages) {
          if (siteObj.productImages.hasOwnProperty(prop) && siteObj.productImages[prop].type === 'magnify') {
            _self._magnifyImage = true;
            break;
          }
        }
      }

      if (!_self._magnifyImage) {
        return false;
      }

      _self._thumbnailImages = [];
      _self._carouselImages = [];
      _self._carouselImages = [];
      _self._extralargeImages = [];
      _self._largeproductImages = [];
      _self._magnifyImages = [];
      _self._allImages = {};
      _self._images = siteObj.productImages;
      _self._imagesLength = _self._images.length;
      _self._imageContainer = document.querySelector('[data-product-image-container]');

      if (_self._imageContainer) {
        _self._usesRoyalSlider = _self._imageContainer.querySelector('.royal-slider');
      }

      _self._allRoyalSliders = _self._usesRoyalSlider ? document.querySelectorAll('.royal-slider') : null;

      //jQuery needed here as royal slider uses it - this will go one day
      _self._rsSlider = _self._usesRoyalSlider ? $('[data-product-image-container] .royal-slider').data('royalSlider') : null;

      _self._createImageArray();

      if (_self._allImages.magnifyImages.length < 2) {
        return false;
      }

      _self._createJzoomBlock();
      _self._replaceProductImages();
      _self._callJthumbs();
    };

    _self._createImageArray = function() {

      var createImageArrayIterator;
      var createImageArrayImagesLength;

      for (createImageArrayIterator = 0, createImageArrayImagesLength = _self._imagesLength; createImageArrayIterator < createImageArrayImagesLength; createImageArrayIterator++) {
        if (_self._images[createImageArrayIterator].name) {
          if (!_self._allImages[_self._images[createImageArrayIterator].type + 'Images']) {
            _self._allImages[_self._images[createImageArrayIterator].type + 'Images'] = [];
          }
          _self._allImages[_self._images[createImageArrayIterator].type + 'Images'].push(_self._images[createImageArrayIterator].name);
        }
      }
    };

    _self._createJzoomBlock = function() {

      var jZoomImages = document.querySelector('.jZoom_imageLinks');

      if (jZoomImages) {

        jZoomImages.parentNode.removeChild(jZoomImages);

        var jZim = document.createElement('div');
        jZim.className = 'jZoom_imageLinks';
        jZim.setAttribute('data-using-xl-image', siteObj.ProductPageExtraLargeImage);

        var jZmag = document.createElement('div');
        jZmag.className = 'jZoom_magnify';

        var jZex = document.createElement('div');
        jZex.className = 'jZoom_extraLarge';

        var jZla = document.createElement('div');
        jZla.className = 'jZoom_large';

        var jZca = document.createElement('div');
        jZca.className = 'jZoom_carousel';


        var jZth = document.createElement('div');
        jZth.className = 'jZoom_thumbnails';

        _self._updateJzoomLinks(jZmag, jZex, jZla, jZca, jZth);

        if (jZim) {
          jZim.appendChild(jZmag);
          jZim.appendChild(jZex);
          jZim.appendChild(jZla);
          jZim.appendChild(jZca);
          jZim.appendChild(jZth);

          document.body.appendChild(jZim);
        }
      }
    };

    _self._updateJzoomLinks = function(jZmag, jZex, jZla, jZca, jZth) {

      //get length of one of the image arrays

      var ln = Math.floor(_self._imagesLength / (Object.keys(_self._allImages).length));
      var updateJzoomLinksIterator;

      for (updateJzoomLinksIterator = 0; updateJzoomLinksIterator < ln; updateJzoomLinksIterator++) {

        var objMag = {};
        objMag['data-j-zoom-image-link-' + updateJzoomLinksIterator] = '';
        objMag['data-image-link'] = _self._allImages.magnifyImages[updateJzoomLinksIterator];

        jZmag.appendChild(_self._createEl('div', objMag));

        var objEx = {};
        objEx['data-j-zoom-image-link-' + updateJzoomLinksIterator] = '';
        objEx['data-image-link'] = _self._allImages.extralargeImages[updateJzoomLinksIterator];

        jZex.appendChild(_self._createEl('div', objEx));

        var objLa = {};
        objLa['data-j-zoom-image-link-' + updateJzoomLinksIterator] = '';
        objLa['data-image-link'] = _self._allImages.largeImages[updateJzoomLinksIterator];

        jZla.appendChild(_self._createEl('div', objLa));


        var objCa = {};
        objCa['data-j-zoom-image-link-' + updateJzoomLinksIterator] = '';
        objCa['data-image-link'] = _self._allImages.carouselImages[updateJzoomLinksIterator];

        jZca.appendChild(_self._createEl('div', objCa));


        var objTh = {};
        objTh['data-j-zoom-image-link-' + updateJzoomLinksIterator] = _self._allImages.thumbnailImages[updateJzoomLinksIterator];
        objTh['data-image-link'] = _self._allImages.thumbnailImages[updateJzoomLinksIterator];

        jZth.appendChild(_self._createEl('div', objTh));
      }
    // call image link making function for each image object type
    };

    _self._createEl = function(el, at) {

      // create element to return
      var r = document.createElement(el);

      // add attributes
      for (var prop in at) {

        if (at.hasOwnProperty(prop) && r) {
          r.setAttribute(prop, at[prop]);
        }
      }

      return r;
    };

    _self._replaceProductImages = function() {

      if (_self._usesRoyalSlider) {

        for (var i = 0, slidersLength = _self._allRoyalSliders.length; i < slidersLength; i++) {
          var thisSlider = _self._allRoyalSliders[i];
          thisSlider.parentNode.removeChild(thisSlider);
        }

        if (_self._rsSlider) {
          _self._rsSlider.destroy(true);
        }
      }

      //lets get the jZoom images first
      var jZoomLinks = document.querySelector('.jZoom_imageLinks');
      var jZextraLarge = jZoomLinks ? jZoomLinks.querySelectorAll('.jZoom_extraLarge') : null;
      var s = document.querySelector('.product-image-slider');
      var iArrayLength = jZextraLarge ? jZextraLarge[0].children.length : 0;
      var container = _self._usesRoyalSlider ? _self._createEl('div', {
        'class': 'royal-slider'
      }) : _self._createEl('div', {
        'class': 'imageContainer'
      });
      var replaceProductImagesIterator;

      for (replaceProductImagesIterator = 0; replaceProductImagesIterator < iArrayLength; replaceProductImagesIterator++) {

        var div = _self._createEl('div', {
          'data-track': 'product-image',
          'class': 'product-image'
        });

        var imObj = {};
        imObj['item-prop'] = 'image';
        imObj['class'] = 'product-img rsImg';
        imObj.rel = replaceProductImagesIterator;
        imObj.alt = '' + 'Image ' + (replaceProductImagesIterator + 1); // todo set the alt tag from siteObj or somewhere else
        imObj.src = jZextraLarge[0].children[replaceProductImagesIterator].getAttribute('data-image-link') ? siteObj.productImagePrefix + jZextraLarge[0].children[replaceProductImagesIterator].getAttribute('data-image-link') : '';
        imObj.style = 'visibility: visible';

        var im = _self._createEl('img', imObj);

        div.appendChild(im);
        container.appendChild(div);
        s.appendChild(container);
      }

      if (_self._usesRoyalSlider) {
        var rSconfig = {
          //autoHeight: true,
          autoPlay: {
            enabled: false,
            delay: 10000
          },
          allowCSS3: false,
          arrowsNav: false,
          addActiveClass: true
        };

        if ($) {
          var newRs = $(container).royalSlider(rSconfig);
          var newRsImages = newRs[0].querySelectorAll('img');
          var newRsImagesLength = newRsImages.length;
          var usesRoyalSliderIterator;
          for (usesRoyalSliderIterator = 0; usesRoyalSliderIterator < newRsImagesLength; usesRoyalSliderIterator++) {
            newRsImages[usesRoyalSliderIterator].style.visibility = 'visible';
          }
        }
      }
    };

    _self._callJthumbs = function() {
      // this is to fix an edge case
      var sliderBlock = document.querySelectorAll('.product-image-slider .product-image');
      var callJthumbsIterator;
      var callJthumbsLength;
      for (callJthumbsIterator = 0, callJthumbsLength = sliderBlock.length; callJthumbsIterator < callJthumbsLength; callJthumbsIterator++) {
        sliderBlock[callJthumbsIterator].style.display = 'block';
      }

      /*
      * Call jZoom Thumbs
      * */
      if (siteObj.siteIsMobile) {
        new jThumbsM();
      } else {
        new jThumbs();
      }
    };

    _self._init();
  };

  return productPageVariationImages;
});
